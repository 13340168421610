<template>
    <div class="pmtdiv">
        <smallbutton :toplabel="name" @click="startAdd()" v-if="showButton" :buttonclass="buttonClass" :disabled="disabled"/>
        <linkbutton :toplabel="name" @click="startAdd()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    <label class="titlelabel">{{name}}</label>
                    <br/>
                    
                    <formentry  isrequired="True" label="Amount $"  v-model="amount"  />
                    <div class="horizFlex">
                        <div class="entry">    
                            <div class="entryTextDiv">
                                <label class="entryLabel" for="">Gift Card Number</label>
                                <label class="required">*</label>
                            </div>
                            <input ref="cardnum" class="entryText capital"  name=""   v-model="numberData"
                                 required="true"  @keyup.enter="balance()">
                            
                        </div>
                        <smallbutton  @click="balance()" toplabel="Balance" buttonclass="balbutton"/>
                    </div>
                    <div class="rightjustified">
                        <label class="ballabel">Balance: ${{giftBalance}}</label>
                    </div>
                        
                    
                    

                   <!--  <div>
                        <label for="" class="required">*Required</label>
                    </div> -->
                    <smallbutton  @click="Save()" toplabel="Save" />
                    <smallbutton  @click="close()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                    
                </form>
                
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../style/format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodal from '../controls/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import datamethods from '../../services/datamethods.js'
    export default {
        name:'addpayment',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:true
            },
            name:{
                type:String,
                default:'Add Payment'
            },
            defaultAmount:{
                type:Number,
            },
            numberDataLabel:{
                type:String,
                default:''
            },
            buttonClass:{
                type:String,
                default:'button buttonpay'
            },
        disabled:{
            type:Boolean,
            default:false
        }
            
        },
        mixins:[format,datamethods],
        data(){
            return{
                
                amount:0,
                numberData:null,
                giftBalance:0
                
            }
        },
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait'
            ]),
            singlePay(){
                if(window.SINGLE_PAYMENT==undefined){return false}
                else{return window.SINGLE_PAYMENT}
            }
        },
        methods:{
            close(){
                this.responseMessage=null
                this.$refs.mymodal.closeModal()
            },
            startAdd(){
                this.numberData=null
                this.responseMessage=null
                this.giftBalance=0
                this.amount = this.defaultAmount.toFixed(2)
                this.$refs.mymodal.openModal()
                this.$nextTick(() => {
                    this.$refs.cardnum.focus();
                    })
            },
            Save(){
                if(Number(this.giftBalance)==0 && Number(this.amount)>0){
                    this.responseMessage="Please enter a gift card with a balance, or click the Balance button." 
                    return;
                }
                if(Number(this.defaultAmount)<Number(this.amount)){
                    this.responseMessage="Please enter an amount less than or equal to the remaining balance owed of $"  + this.defaultAmount
                    return;
                }
                if(Number(this.amount)==0){
                    this.responseMessage="Please enter an amount greater than $0"  
                    return;
                }
                if(Number(this.giftBalance)<Number(this.amount)){
                    this.responseMessage="Please enter an amount less than or equal to the gift card balance of $" + this.giftBalance
                    return;
                }
                //if single pay, must be at least the def amt
                if(this.singlePay){
                    if(Number(this.amount)< Number(this.defaultAmount)){
                        this.responseMessage="Please enter an amount at least equal to $"  + Number(this.defaultAmount).toFixed(2)
                        return
                    }
                }
                this.$emit('save',this.name, this.amount,this.numberData)
                this.$refs.mymodal.closeModal()
            },
            async balance(){
                if (this.numberData=='' || this.numberData==null){return}
                let req = {
                    CardNumber:this.numberData
                }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'giftcardbalance')
                //handle response
            
                if(response.Successful){
                    this.giftBalance=response.StringResponse;
                    if(Number(this.giftBalance)<Number(this.amount)){
                        this.amount = this.giftBalance
                    }
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            }
        }
    }
    </script>
    
    <style>
    .balbutton{
        height:25px;
        background-color:var(--button-color);
        color:var(--light-text-color);
        border:none;
        border-radius:2px;
        padding:5px;
        margin-top:5px;
    }
    .ballabel{
        margin-left:32px;
    }
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    