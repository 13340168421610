import Vue from 'vue'
import Vuex from 'vuex'

import {getField, updateField} from 'vuex-map-fields'
import dataModel from './dataModel.js'
Vue.use(Vuex)
export const store = new Vuex.Store({
    state:{
        needsUpdate:false,
        
        pleaseWait:false,
        responseMessage:'',
        imageBasePath:null,
        
        //general
        currentNav:'wallet',
        username:'',
        oldpassword:'',
        loggedInUser:{
            name:'User',
            isAdmin:false,
            user_personId:0
        },
        loggedIn:false,
        securityAppName:'POS',
        securityAppItems:[],
        requireLogin:true,
        logoOverride:'',

        //location
        locationName: '',
        location: {
            id:-1,
            name:null
        },
        businessDayId:-1,
        terminalId:1,
        
        //ticket
        ticket:{
            id:0
        },

        //settings that we want to cache
        allowUpdateGuest: true,

        //localization that we want to cache
        guestText: 'Guest',

        //structure
        checksetindex:true,
        panels: [],

        //history
        transactions: [],
        ShowHistory: false,

        //guest
        guestList:[],
        guest: {},
        guestAccounts:[],
        guestLocationId:-1,
        
        //cart
        availableCartItemTypes:[],
        selectedCartItemType:0,
        cartItems: [],
        availableCartItems: [],
        cartItemCount: 0,
        cartTotal: 0,
        cartTax:0,
        cartSubTotal:0,
        cartEligibleForFA:false,
        faBalance:-1,
        isReturn:false,

        //pay
        payItemCount: 0,
        payTotal:0,
        payItems:[],
        inPayment: false,
        transactionComplete: false,
        due:0,
        change:0,
        isCash:false,
        arPaymentItemSubtypes:[],
        //ARPaymentItemSubtypeId:0,
        //ARPaymentItemSubtypeName:null,
        suspendedTicketCount:0,
        isCompletable:false,

        //receipt
        receiptIP:null
    },
    mutations:{
        updateField,
        resetbeforeapi(state){
            state.pleaseWait=true;
            state.responseMessage=''
        },
        setupinitialpanels(state){
            state.panels=dataModel.initialPanels();
        },
        setPanel(state,panelIndex) {
            if (state.panels[panelIndex].available) {
                var i;
                for (i = 0; i <= state.panels.length - 1; i++) {
                    state.panels[i].active = false;
                }
                state.panels[panelIndex].active = true;
                
                state.responseMessage='';
            } else {
            }
        },
        resetData(state){
            state.guestList=[]
            state.guest={}
            state.cartItems=[]
            state.cartItemCount= 0
            state.cartTotal= 0
            state.cartTax=0
            state.cartSubTotal=0
            state.payItems=[]
            state.payItemCount= 0
            state.payTotal=0
            state.due=0
            state.change=0
            state.isCash=false
            state.pleaseWait=false
            state.responseMessage=''
            state.isReturn=false
            state.checksetindex=true
            state.isCompletable=false
            // state.selectedCartItemType=0
        },
        resetData_Cart(state){
            
            state.cartItems=[]
            state.cartItemCount= 0
            state.cartTotal= 0
            state.cartTax=0
            state.cartSubTotal=0
            state.payItems=[]
            state.payItemCount= 0
            state.payTotal=0
            state.due=0
            state.change=0
            state.isCash=false
            state.pleaseWait=false
            state.responseMessage=''
            // state.selectedCartItemType=0
            state.isReturn=false
            state.checksetindex=true
            state.isCompletable=false
        },
        buildcart(state){
            console.log('buildcart')
            state.guestList=[]
            state.guest={
                id:state.ticket.guestId,
                name:state.ticket.guestName
            }
            state.panels[0].title = state.guestText + ': ' + state.guest.name ;
            state.panels[0].valid = true;
            state.panels[1].available = true;
            state.cartItems=[]
            state.cartTotal= 0
            state.cartTax=0
            state.cartSubTotal=0

            state.payItems=[]
            state.payItemCount= 0
            state.payTotal=0
            state.due=0
            state.change=0
            state.isCash=false

            var i;
            state.cartItemCount= state.ticket.lineItems.length - 1
            for (i = 0; i <= state.ticket.lineItems.length - 1; i++) {
                let newitem={
                    id:state.ticket.lineItems[i].productId,
                    lineItemId:state.ticket.lineItems[i].id,
                    name:state.ticket.lineItems[i].productName,
                    quantity:state.ticket.lineItems[i].quantity,
                    price:state.ticket.lineItems[i].amount,
                    discount:state.ticket.lineItems[i].discountAmount,
                    isFinancialAidEligible:state.ticket.lineItems[i].isFinancialAidEligible,
                    unitPrice:state.ticket.lineItems[i].unitPrice
                }
                state.cartItems.push(newitem) 
            }

     
             state.payItemCount= state.ticket.paymentItems.length - 1
            for (i = 0; i <= state.ticket.paymentItems.length - 1; i++) {
                let newitem={
                    id:state.ticket.paymentItems[i].id,
                    name:state.ticket.paymentItems[i].typeName,
                    numberData:state.ticket.paymentItems[i].numberData,
                    price:state.ticket.paymentItems[i].amount,
                    authorizationCode:state.ticket.paymentItems[i].authorizationCode
                }
                state.payItems.push(newitem) 
            }

            //go to cart
            state.panels[0].active=0
            state.panels[1].active=1

            if(state.payItemCount>0){
                state.panels[1].active=0
                state.panels[2].active=1
            }

            state.pleaseWait=false
            state.responseMessage=''
        }
    },
    getters:{
        getField,
    },
    actions:{
        beforeAPI(context){
            context.commit('resetbeforeapi'); 
        },
        setupPanels(context){
            context.commit('setupinitialpanels')
        },
        setActivePanel(context,panelIndex){
            context.commit('setPanel',panelIndex)
        },
        startOver(context){
            context.commit('setupinitialpanels')
            context.commit('resetData') 
            
            
        },
        startOver_Cart(context){
            context.commit('resetData_Cart')
        },
        buildCart(context){
            
            context.commit('buildcart')
        }
        
    }
})